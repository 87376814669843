<template>
  <div class="pinInput__box">
    <div
      class="input-wrapper"
      @input="exportPin"
    >
      <PincodeInput
        v-model="value"
        :secure="false"
        :autofocus="false"
      />
    </div>
  </div>
</template>

<script>
import PincodeInput from "vue-pincode-input";
export default {
  components: {
    PincodeInput,
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    exportPin() {
      const pin = this.value;
        this.$emit("enter", pin);
    },
  },
};
</script>

<style lang="scss">
.vue-pincode-input {
  margin: 15px;
  max-width: 70px !important;
  box-shadow: none !important;
}

.input-wrapper {
  width: 100%;
}

.vue-pincode-input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

input.vue-pincode-input {
  background: #ebebeb;
  width: 50px;
  height: 50px;
  color: #806157;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  // border: none;
  // border: 2px dashed #e5e5e5;
  outline: 1px solid #d9d9d9 !important;
  overflow: hidden;
  border-radius: 400px;
  font: {
    size: 30px;
  }
}

.pinInput {
  // background: white;
  width: 50px;
  height: 50px;
  color: #ff4f79;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  // border: none;

  overflow: hidden;
  border-radius: 400px;
  font: {
    
    size: 30px;
  }
  &__box {
    // width: 35%;
    // height: 80px;
    padding: 5px;
    // background-color: #e9e6e6;
    // border: 2px dashed #fff;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }
}
</style>
