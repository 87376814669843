<template>
  <div class="setupWrapper">
    <!-- <AppLoader v-if="isLoading" /> -->
    <div
      style="
        display: grid;
        grid-template-columns: 80px 1fr 80px;
        align-items: start;
      "
    >
    <span class="justify-self-start">
        <img
          class="mx-auto"
          src="../../assets/images/logo-color.png"
          alt="Logo"
          style="
            max-width: 50px;
            object-fit: contain;
          "
      /></span>
      <StepNavigation
        :steps="global_steps"
        :currentstep="2"
        style="margin-bottom: 50px; min-width: 175px"
      >
      </StepNavigation>
      <span
        @click="skipOnboarding"
        style="justify-self: end; color: #c4c4c4; font-weight: 600"
      >
        SKIP
      </span>
    </div>
    <!-- <ProgressBar :wizard-step="Number(2)" /> -->
    <div class="setupWrapper__title">
      <span v-if="childrenArray.length > 0"
        >Nice one! Let’s add a mission next!</span
      >
      <span v-else>Let's add your children to the app!</span>
    </div>
    <div style="margin-bottom: 30px; margin-top: 10px">
      <!-- v-if="getSetupChildrens.length == 0" -->

      <section id="childrens">
        <div v-if="childrenArray.length > 0" class="recent_container">
          <div class="activity_item border border-[#e3e3e3]">
            <div class="activity_item__container">
              <div class="activity_item__icon">
                <img :src="childrenArray[0].user.avatarLinkEncode" />
              </div>
              <div>
                <div class="activity_item__title">
                  {{
                    childrenArray[0].user.first_name +
                    " " +
                    childrenArray[0].user.last_name
                  }}
                </div>
                <div class="activity_item__description">
                  @{{ childrenArray[0].user.username }}
                </div>
              </div>
            </div>
            <div class="activity_item__amount">
              <button
                style="
                  width: 20px;
                  height: 20px;
                  border: none;
                  background: var(--red);
                  border-radius: 50%;
                  padding: 4px;
                "
                @click="deleteChild(childrenArray[0].user.id)"
              >
                <svg
                  width="100%"
                  height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="4"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section v-if="childrenArray.length == 0" id="children" class="mt-8">
        <button
          class="flex items-center w-full rounded-full border border-primary p-6 justify-center"
          @click="openSheetAddChild"
        >
          <img
            src="@/pages/guardian/assets/images/user-add.svg"
            alt="Add Children"
          />
          <span class="text-primary ml-3 font-semibold">Add Child</span>
        </button>
      </section>
    </div>

    <Sheet
      :show.sync="sheetChildAdd"
      :on-close="closeSheetAddChild"
      :isAddingChild="true"
    >
      <div class="sticky top-0 flex justify-between items-center">
        <button
          v-if="currentstep != 1"
          class="w-[20px] h-[20px]"
          @click="stepBack(currentstep)"
        >
          <img src="../../assets/images/back-arrow.svg" alt="Close" />
        </button>
        <div v-else class="w-[20px] h-[20px]"></div>
        <div class="bottomsheet-title">
          <StepNavigation
            :steps="steps"
            :currentstep="currentstep"
            style="min-width: 200px !important"
          >
          </StepNavigation>
        </div>
        <span>
          <button class="w-[20px] h-[20px]" @click="closeSheetAddChild">
            <img src="../../assets/images/close.png" alt="Close" />
          </button>
        </span>
      </div>

      <div style="flex: 1">
        <h1 class="text-left text-[14px] text-primary mt-10 mb-6 font-semibold">
          Add a child
        </h1>
        <hr
          style="
            width: calc(100% + 2rem);
            margin-left: -1rem;
            color: #e8e8e8;
            margin-bottom: 20px;
          "
        />

        <div v-if="currentstep == 1" class="addMissionExplChild">
          <AppInput
            v-model="child.first_name"
            required
            type="text"
            name="Enter your first child’s name"
            label="first_name"
          />

          <AppInput
            v-model="child.last_name"
            required
            type="text"
            name="Enter your child’s last name"
            label="last_name"
          />
        </div>

        <div v-if="currentstep == 2" class="addMissionExplChild">
          <AppInput
            v-model="child.username"
            :required="true"
            type="text"
            name="Enter a username for your child"
            label="username"
          />
        </div>

        <div v-if="currentstep == 3" class="addMissionExplChild">
          <!-- <AppInput
            v-model="child.date"
            :required="true"
            type="date"
            :max="maxDate"
            :name="'Enter ' + child.first_name + '’s date of birth'"
            label="birth"
            style="margin-bottom: 20px; -webkit-appearance: none"
          /> -->
          <div class="text-xs font-semibold text-secondary mb-[10px]">
            Enter {{ child.first_name }}'s date of birth
          </div>
          <dropdown-datepicker
            :on-day-change="onDayChange"
            :on-month-change="onMonthChange"
            :on-year-change="onYearChange"
            defaultDateFormat="dd-mm-yyyy"
            displayFormat="dmy"
            submitFormat="yyyy-mm-dd"
            :maxDate="todaysDate"
            :required="true"
            :defaultDate="getDefaultDate"
          ></dropdown-datepicker>

          <p
            style="
              margin-bottom: 10px;
              margin-top: 20px;
              pointer-events: none;
              font-size: 11px;
              color: #7a615a;
              font-weight: 600;
            "
          >
            Select {{ child.first_name }}'s gender
          </p>

          <select
            name="gender"
            @change="onSelectChange($event)"
            class="gender-select"
            v-model="key"
          >
            <option value="" selected disabled>Gender</option>
            <option value="m">Boy</option>
            <option value="f">Girl</option>
          </select>

          <!-- <div style="display: flex">
            <div style="margin-right: 20px">
              <input
                name="radio"
                id="radio-1"
                type="radio"
                @click="setChildGender('m')"
              />
              <label for="radio-1" style="margin-left: 10px">Boy</label>
            </div>

            <div style="margin-right: 20px">
              <input
                name="radio"
                id="radio-2"
                type="radio"
                @click="setChildGender('f')"
              />
              <label for="radio-2" style="margin-left: 10px">Girl</label>
            </div>
          </div> -->
        </div>

        <div
          v-if="currentstep == 4"
          class="addMissionExplChild"
          style="display: flex; justify-content: center; flex-direction: column"
        >
          <div style="display: flex !important; justify-content: center">
            <div>
              <label class="mb-4 -mt-3 text-xs font-bold leading-4 block"
                >Enter a PIN for your child
              </label>
              <PinInput @enter="getPass" class="addChildPinInput" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentstep == 1" class="flex justify-between mt-6">
        <!-- <button
          disabled="disabled"
          class="bg-[#D76261] rounded-[100px] py-3 px-12 text-white text-xs font-bold opacity-0"
        >
          Back
        </button> -->
        <button
          :style="[validateStep_1 ? { opacity: '.5' } : {}]"
          @click="changeStep(1)"
          :disabled="validateStep_1"
          class="bg-[#58C1E1] w-full h-[44px] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Next
        </button>
      </div>

      <div v-if="currentstep == 2" class="flex justify-between mt-6">
        <!-- <button
          @click="currentstep = 1"
          class="bg-[#D76261] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Back
        </button> -->
        <button
          :style="[validateStep_2 ? { opacity: '.5' } : {}]"
          @click="changeStep(2)"
          :disabled="validateStep_2"
          class="bg-[#58C1E1] w-full h-[44px] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Next
        </button>
      </div>

      <div v-if="currentstep == 3" class="flex justify-between mt-6">
        <!-- <button
          @click="currentstep = 2"
          class="bg-[#D76261] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Back
        </button> -->
        <button
          :style="[validateStep_3 ? { opacity: '.5' } : {}]"
          @click="changeStep(3)"
          :disabled="validateStep_3"
          class="bg-[#58C1E1] w-full h-[44px] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Next
        </button>
      </div>

      <div v-if="currentstep == 4" class="flex justify-between mt-6">
        <!-- <button
          @click="currentstep = 3"
          class="bg-[#D76261] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Back
        </button> -->
        <button
          :style="[validateStep_4 ? { opacity: '.5' } : {}]"
          @click="changeStep(4)"
          :disabled="validateStep_4"
          class="bg-[#58C1E1] w-full h-[44px] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Next
        </button>
      </div>
    </Sheet>

    <Sheet
      :show.sync="sheetChildAddedInfo"
      :on-close="closeSheetChildAddedInfo"
    >
      <h1 class="text-[24px] font-bold mb-8 text-center">Child added!</h1>
      <div v-if="lastAddedChildGetter !== null" class="text-center" style="flex: 1">
        <img
          :src="lastAddedChildGetter.avatar"
          class="w-[100px] h-[100px] mb-8 mx-auto"
          alt="Avatar"
        />
        <h3 class="text-[20px] font-bold">
          {{ lastAddedChildGetter.last_name }}
          {{ lastAddedChildGetter.first_name }}
        </h3>
        <p
          class="text-[14px] text-[#AAAAAA]"
        >
          @{{ lastAddedChildGetter.username }}
        </p>
        <p
          class="text-[14px] text-[#AAAAAA]"
        >
          {{ lastAddedChildGetter.date_birth }}
        </p>
        <p class="text-[12px] text-[#AAAAAA] my-12">
          Give this information to {{ lastAddedChildGetter.last_name }}! Don’t worry if you forget the passcode,
          you can access it from the settings!
        </p>

        <h3 class="text-[20px] font-bold mb-2">PIN Code</h3>
        <div
          class="flex font-bold text-primary justify-center gap-[10px]"
        >
          <!-- {{lastAddedChildGetter.password}}
            {{lastAddedChildGetter}} -->
          <div
            class="rounded-full border border-primary text-xl w-[50px] h-[50px] flex items-center justify-center font-bold"
          >
            {{ lastAddedChildGetter.password[0] }}
          </div>
          <div
            class="rounded-full border border-primary text-xl w-[50px] h-[50px] flex items-center justify-center font-bold"
          >
            {{ lastAddedChildGetter.password[1] }}
          </div>
          <div
            class="rounded-full border border-primary text-xl w-[50px] h-[50px] flex items-center justify-center font-bold"
          >
            {{ lastAddedChildGetter.password[2] }}
          </div>
          <div
            class="rounded-full border border-primary text-xl w-[50px] h-[50px] flex items-center justify-center font-bold"
          >
            {{ lastAddedChildGetter.password[3] }}
          </div>
        </div>
      </div>
      <button
        @click="closeSheetChildAddedInfo"
        class="bg-[#58C1E1] w-full h-[44px] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
      >
        Finish
      </button>
    </Sheet>

    <div class="nextButtons">
      <button
        :disabled="validateButton"
        class="nextButtons__next"
        :class="{
          nextButtons__active: childrenArray.length > 0,
        }"
        @click="validateNextStep"
      >
        Next step
      </button>
      <!-- <button class="nextButtons__skip" @click="skipOnboarding">
        Skip to dashboard
      </button> -->
    </div>
  </div>
</template>

<script>
  import AppInput from "../../components/AppInput";
  import PinInput from "../../components/PinInput";
  import StepNavigation from "../../components/wizard/StepNavigation";
  // import StepControls from "../../components/wizard/StepControls";
  import "../../assets/css/vue-form-wizard.css";
  import Sheet from "../../components/Sheet";
  // import ProgressBar from "../../components/ProgressBar";
  // import AppLoader from "../../components/AppLoader";
  import config from "../../config";
  import axios from "axios";
  import status from "../../utils/statusCode";
  import swal from "sweetalert";
  import moment from "moment";
  import { mapActions } from "vuex";
  import { mapGetters } from "vuex";
  import DropdownDatepicker from "vue-dropdown-datepicker";

  export default {
    components: {
      Sheet,
      AppInput,
      // AppLoader,
      // ProgressBar,
      PinInput,
      StepNavigation,
      DropdownDatepicker,
      // StepControls,
    },
    props: {
      next: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        birthday: null,
        birthmonth: null,
        birthyear: null,
        gender: null,
        key: "",
        sheetChildAddedInfo: false,
        childrenArray: [],
        isLoading: false,
        sheetChildAdd: false,
        modalKey: 0,
        currentstep: 1,
        indicatorclass: true,
        step: 1,
        active: 1,
        firststep: true,
        nextStep: "",
        lastStep: "",
        laststep: false,
        global_steps: [
          {
            id: 1,
            title: "STEP 1",
            icon_class: "fa fa-map-marker",
          },
          {
            id: 2,
            title: "STEP 2",
            icon_class: "fa fa-folder-open",
          },
          {
            id: 3,
            title: "STEP 3",
            icon_class: "fa fa-paper-plane",
          },
        ],
        steps: [
          {
            id: 1,
            title: "STEP 1",
            icon_class: "fa fa-map-marker",
          },
          {
            id: 2,
            title: "STEP 2",
            icon_class: "fa fa-folder-open",
          },
          {
            id: 3,
            title: "STEP 3",
            icon_class: "fa fa-paper-plane",
          },
          {
            id: 4,
            title: "STEP 4",
            icon_class: "fa fa-paper-plane",
          },
        ],
        /////////////////////////////
        modal: false,
        modalHasError: false,
        editModal: false,
        child: {
          id: null,
          first_name: "",
          last_name: "",
          name: "",
          username: "",
          date: "",
          gender: "",
          password: "",
          passwordConfirm: "",
          file: {},
        },
        error: "",
        red: "blue",
        activeAvatar: true,
        childIndex: 0,
        editChild: {
          index: "",
          first_name: "",
          last_name: "",
          username: "",
          date_birth: "",
        },
        file: "",
        changeTodefault: false,
        src: "",
        imgName: "",
        previewImage: null,
        uploadedAvatarBase64: null,
        childrens: [],
        currentEditAvatar: null,
        editOrNormal: false,
        editActiveAvatar: false,
        hideEditAvatarToggleCircle: false,
      };
    },
    computed: {
      ...mapGetters(["getSetupChildrens", "lastAddedChildGetter"]),
      todaysDate() {
        return moment(new Date()).format("YYYY-MM-DD");
      },
      validateStep_1() {
        if (this.child.last_name != "" && this.child.first_name != "") {
          return false;
        } else {
          return true;
        }
      },
      validateStep_2() {
        if (this.child.username != "") {
          return false;
        } else {
          return true;
        }
      },
      validateStep_3() {
        if (
          this.birthday != null &&
          this.birthmonth != null &&
          this.birthyear != null &&
          this.gender != null
        ) {
          return false;
        } else {
          return true;
        }
      },
      validateStep_4() {
        if (this.child.password != "" && this.child.password.length == 4) {
          return false;
        } else {
          return true;
        }
      },
      validateChild() {
        if (
          this.child.first_name != "" &&
          this.child.last_name != "" &&
          this.child.username != "" &&
          this.child.date.length == 10 &&
          this.child.password != "" &&
          this.child.passwordConfirm != ""
        )
          return this.red;
        else return "";
      },
      validateButton() {
        return !(this.childrenArray.length > 0);
      },
      editValidateChild() {
        if (
          this.editChild.first_name != "" &&
          this.editChild.last_name != "" &&
          this.editChild.username != "" &&
          this.editChild.date_birth.length == 10
        )
          return "red";
        else return "";
      },
      state() {
        return this.$store.state;
      },
      maxDate() {
        return new Date().toISOString().split("T")[0];
      },
      getDefaultDate() {
        return this.birthyear + "-" + this.birthmonth + "-" + this.birthday;
      },
    },
    async created() {
      this.fetchChildren();

      if (
        this.childrenArray &&
        this.childrenArray != "undefined" &&
        this.childrenArray.length > 0
      ) {
        console.log(this.childrenArray);
        console.log(this.$store.state.setup);
        console.log("show childrens inside if statement");
      }
    },
    methods: {
      ...mapActions([
        "addChildAction",
        "deleteChildAction",
        "editChildAction",
        "fetchSetupChildrens",
        "TOAST",
      ]),
      onSelectChange(event) {
        this.gender = event.target.value;
      },
      onDayChange(day) {
        if (day < 10) {
          this.birthday = "0" + day;
        } else {
          this.birthday = day;
        }
      },
      onMonthChange(month) {
        if (month < 10) {
          this.birthmonth = "0" + month;
        } else {
          this.birthmonth = month;
        }
      },
      onYearChange(year) {
        this.birthyear = year;
      },
      stepBack(step) {
        if (step > 1) {
          this.currentstep = this.currentstep - 1;
        }
        console.log("from back");
      },
      changeStep(step) {
        if (step === 1) {
          if (this.child.first_name != "" && this.child.last_name != "") {
            this.currentstep = 2;
          }
        }

        if (step === 2) {
          if (this.child.username != "") {
            this.currentstep = 3;
          }
        }

        if (step === 3) {
          if (
            this.birthday != null &&
            this.birthmonth != null &&
            this.birthyear != null &&
            this.gender != null
          ) {
            this.currentstep = 4;
          }
        }

        if (step === 4) {
          if (this.child.password != "") {
            this.addChild();
          }
        }
      },
      openSheetChildAddedInfo() {
        this.sheetChildAddedInfo = true;
      },
      closeSheetChildAddedInfo() {
        this.sheetChildAddedInfo = false;
        this.$store.state.lastAddedChild = null;
        this.fetchChildren();
      },
      async fetchChildren() {
        const token = localStorage.getItem("guardian_token");
        const userId = localStorage.getItem("guardian_user_id");
        const family = localStorage.getItem("guardian_family_id");

        const response = await axios.post(config.API_HOST + "/children", {
          userId: userId,
          token: token,
          family_id_check: family,
        });
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          this.childrenArray = response.data.data;

          if (response.data.data.length > 0) {
            localStorage.setItem(
              "guardian_setup_active_child_id",
              response.data.data[0].users_id
            );
          }
        } else if (status.invalidToken(statusCode)) {
          this.deleteStorage();
        } else if (status.error(statusCode)) {
          this.TOAST("Something went wrong! Please contact our support team.");
        }
      },
      openSheetAddChild() {
        this.child = {
          name: "",
          username: "",
          date: "",
          password: "",
          passwordConfirm: "",
          file: {},
          avatar: null,
        };
        this.sheetChildAdd = true;
      },
      closeSheetAddChild() {
        this.sheetChildAdd = false;
      },
      stepChanged: function (step) {
        this.currentstep = step;
        console.log(step);
      },
      onComplete: function () {
        alert("Yay. Done!");
      },
      setChildGender(value) {
        this.child.gender = value;
      },
      resolveImage(src) {
        return config.HOST + src;
      },
      getPass(pin) {
        this.child.password = pin;
        // console.log(pin);
        // console.log(this.child.password);
      },
      confirmPass(pin) {
        this.child.passwordConfirm = pin;
        // console.log(pin);
        // console.log(this.child.passwordConfirm);
      },
      openModal() {
        // this.modal = true;
        this.child = {
          name: "",
          username: "",
          date: "",
          password: "",
          passwordConfirm: "",
          file: {},
          avatar: null,
        };
        this.$store.state.childAddModal = true;
      },
      openEditModal(index) {
        this.editOrNormal = false;
        this.editModal = true;
        this.childIndex = index;
        this.editChild.id =
          this.$store.state.setup.children[this.childIndex].id;
        this.editChild.first_name =
          this.$store.state.setup.children[this.childIndex].first_name;
        this.editChild.last_name =
          this.$store.state.setup.children[this.childIndex].last_name;
        this.editChild.index = this.childIndex;
        this.editChild.username =
          this.$store.state.setup.children[this.childIndex].username;
        this.editChild.date_birth =
          this.$store.state.setup.children[this.childIndex].date_birth;
        this.editChild.password =
          this.$store.state.setup.children[this.childIndex].password;
        this.editChild.avatar =
          this.$store.state.setup.children[this.childIndex].avatar;
        this.currentEditAvatar =
          this.$store.state.setup.children[this.childIndex].avatar;
      },
      closeModal() {
        // this.modal = false;
        this.$store.state.childAddModal = false;
        this.error = "";
        this.child.username = "";
        this.child.name = "";
        this.child.date = "";
        this.child.password = "";
        this.child.passwordConfirm = "";
        this.uploadedAvatarBase64 = null;
        this.currentstep = 1;
        this.modalKey = -1;
      },
      closeEditModal() {
        this.editModal = false;
        this.error = "";
        this.editOrNormal = true;
        this.currentEditAvatar = null;
      },
      // async addChild() {
      //   this.error = "";
      //   const { child } = this;
      //   const passRegex = /^\d{4}$/;

      //   if (!passRegex.test(child.password)) {
      //     this.error = "Password must be a 4 digit code!";
      //   } else {
      //     await this.addChildAction(child).then(() => {
      //       this.closeSheetAddChild();
      //       this.fetchChildren();
      //       console.log("fetched from add");
      //     });
      //   }
      // },
      editChildSave() {
        this.editChild.avatar = this.currentEditAvatar;
        this.editChildAction(this.editChild);
        this.closeEditModal();
        this.editChild = {
          index: "",
          first_name: "",
          last_name: "",
          username: "",
          date_birth: "",
        };
      },
      async addChild() {
        this.error = "";
        const { child } = this;
        this.child.date =
          this.birthyear + "-" + this.birthmonth + "-" + this.birthday;
        this.child.gender = this.gender;
        const passRegex = /^\d{4}$/;

        if (!passRegex.test(child.password)) {
          this.error = "Password must be a 4 digit code!";
        } else {
          await Promise.all([this.addChildAction(child)]).then(() => {
            this.closeSheetAddChild();
            this.openSheetChildAddedInfo();
          });

          this.child.username = "";
          this.child.first_name = "";
          this.child.last_name = "";
          this.child.date = "";
          this.child.password = "";
          this.child.passwordConfirm = "";
          this.uploadedAvatarBase64 = null;
          this.currentstep = 1;
          this.modalKey = -1;
          this.birthday = null;
          this.birthmonth = null;
          this.birthyear = null;
          this.gender = null;
        }
      },
      // async addChild() {
      //   const token = localStorage.getItem("guardian_token");
      //   const userId = localStorage.getItem("guardian_user_id");
      //   const family = localStorage.getItem("guardian_family_id");
      //   this.error = "";
      //   const { child } = this;
      //   const passRegex = /^\d{4}$/;

      //   if (!passRegex.test(child.password)) {
      //     this.error = "Password must be a 4 digit code!";
      //   } else {
      //     const response = await axios.post("/users/add", {
      //       userId: userId,
      //       token: token,
      //       family_id: family,
      //       username: child.username,
      //       password: child.password,
      //       first_name: child.first_name,
      //       last_name: child.last_name,
      //       sex: child.gender,
      //       type: "child",
      //       date_birth: child.date,
      //       avatar: child.avatar,
      //     });

      //     const { statusCode } = response.data;

      //     if (status.success(statusCode)) {
      //       if (response.data.data) {
      //         const details = {
      //           child: child,
      //           response: response.data.data,
      //         };
      //         this.$store.commit("ADD_USER", details);

      //         setTimeout(() => {
      //           this.TOAST("Your child was added successfully!");
      //         }, 2000);
      //       } else {
      //         this.TOAST(response.data.message);
      //       }
      //     } else if (status.invalidToken(statusCode)) {
      //       deleteStorage();
      //     } else if (status.error(statusCode)) {
      //       this.TOAST(response.data.message);
      //     }

      //     this.fetchChildren();
      //     this.closeSheetAddChild();

      //     this.child.username = "";
      //     this.child.first_name = "";
      //     this.child.last_name = "";
      //     this.child.date = "";
      //     this.child.password = "";
      //     this.child.passwordConfirm = "";
      //     this.uploadedAvatarBase64 = null;
      //     this.currentstep = 1;
      //   }
      // },
      async deleteChild(childId) {
        const token = localStorage.getItem("guardian_token");
        const userId = localStorage.getItem("guardian_user_id");
        const family = localStorage.getItem("guardian_family_id");

        const response = await axios.post("/users/delete/" + childId, {
          userId: userId,
          token: token,
          family_id_check: family,
        });

        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          this.TOAST("Your child was deleted successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          this.TOAST("Something went wrong! Please contact our support team.");
        }

        this.fetchChildren();

        console.log("response: ", response);
      },
      // deleteChild(childId) {
      //   this.deleteChildAction(childId).then(() => {
      //     this.fetchChildren();
      //     console.log("fetched from delete");
      //   });
      // },
      showAvatar(value) {
        this.activeAvatar = true;
        this.child.avatar = value;
        this.uploadedAvatarBase64 = null;
      },
      toggleAvatar(value) {
        if (this.editModal == true) {
          this.editChild.avatar = value;
          this.currentEditAvatar = value;

          this.activeAvatar = null;

          this.hideEditAvatarToggleCircle = false;
        } else {
          // this.activeAvatar = false;
          this.child.avatar = value;
          this.uploadedAvatarBase64 = value;
        }
      },
      editShowAvatar(value) {
        // this.editChild.avatar = '';
        this.currentEditAvatar = value;
        this.hideEditAvatarToggleCircle = true;
        this.activeAvatar = true;
        // console.log(value);
        // this.activeAvatar = true;
        // this.child.avatar = value;
        // this.uploadedAvatarBase64 = null;

        // this.editChild.avatar = this.$store.state.setup.children[this.childIndex].avatar;
        // this.currentEditAvatar = this.$store.state.setup.children[this.childIndex].avatar;
      },
      editToggleAvatar() {
        // this.editChild.avatar = value;
        // console.log(value);
        // console.log("2");
        this.activeAvatar = null;
      },
      validateNextStep() {
        if (this.childrenArray.length > 0) {
          this.$router.replace("/guardian/setup/missions");
        }
      },
      skipOnboarding() {
        swal({
          title: "Are you sure you want to skip to the dashboard?",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.$router.replace("/guardian");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appForm__avatarText {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #7a615a;
    margin-top: 20px;
  }

  .appForm__avatars {
    display: flex;
    flex-direction: row;
    margin-top: 35px;

    &__inline {
      display: block;
    }
  }

  .setupMain__title {
    width: 100%;
    margin-bottom: 20px;
    font-size: 25px;
    text-align: center;
  }

  .setupMain__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    margin-top: 30px;
  }

  .next__Button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
  }

  @media screen and (max-width: 850px) {
    .appForm__avatars {
      &__inline {
        display: none;
      }
    }

    .appForm__avatars__second {
      display: block;
      margin-top: 15px;
    }
  }

  @media screen and (max-width: 1180px) and (min-width: 480px) {
    .setupMain__button {
      padding: 0px 10px;
      height: 70px;
      box-sizing: border-box;
      margin: 0 auto;
      margin-top: 30px;
      bottom: 0;
      width: calc(100vw - 1020px);
      text-decoration: none;
    }
  }

  #setup {
    padding: 20px 30px;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    #setup {
      padding: 20px;
      flex-direction: column-reverse;
    }
  }

  @media screen and (max-width: 800px) {
    .appChat,
    .setupSummary__title {
      display: none;
    }
    .setupSummary__stepsItemTitle {
      font-size: 16px;
    }
    .setupSummary__stepsItemDescription {
      font-size: 12px;
    }
    .setupMain__title {
      font-size: 20px;
      text-align: center;
      padding: 0 30px;
    }
    .appChildCard {
      margin-left: 20px;
      width: 90%;
    }
    .next__Button {
      position: fixed;
      bottom: 0;
      left: 0;
      background: white;
    }
    .appButton {
      height: 20px;
      margin-top: 10px;
    }
    .addChild__card__open {
      width: 100% !important;
    }
    .setupMain__content {
      margin: 0;
      padding: 0;
      position: relative;
    }
    .setupMain__content::-webkit-scrollbar {
      display: none !important;
    }
    .addCard {
      width: 90%;
      border-radius: 20px;
      margin-bottom: 20px;
    }
  }

  .passLabel {
    font-size: 16px;
    margin-top: 20px;
    color: #a6a6a6;
  }

  @media screen and (max-width: 550px) {
    .appChildCard {
      width: 100%;
      margin-left: 0;
    }
    .addCard {
      margin-bottom: 0;
    }
    .setupMain__title {
      padding: 0 10px;
    }
  }

  #children {
    h1 {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.02rem;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    }

    .family_container {
      margin-top: 15px;
      background: white;
      padding: 25px;
      border-radius: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .family_text {
      color: var(--ternary);
      text-align: right;

      &__title {
        font-weight: bold;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 0.03rem;
        color: var(--main);
      }

      &__amount {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0.02rem;
        color: var(--main);
        line-height: 20px;
      }
    }

    .family_button {
      width: 60px;
      height: 60px;
      border: none;
      border-radius: 50%;
      background: var(--main);
      display: flex;
      justify-content: center;
      align-items: center;

      &:active {
        opacity: 0.8;
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  #childrens {
    h1 {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.02rem;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    }

    .recent_container {
      margin-top: 20px;
    }

    .activity_item {
      width: 100%;
      min-height: 80px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      // background: var(--gray);
      background: white;
      // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(18, 205, 254, 0.2) 0px 0px 0px 1px;
      // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, #7c8db515 0px 0px 0px 1px;
      padding: 15px;
      border-radius: 100px;
      position: relative;
      overflow: hidden;
      transition: all 200ms ease-in-out !important;
      justify-content: space-between;

      &:active {
        opacity: 0.6;
      }

      &__container {
        display: flex;
        align-items: center;
      }

      &__title {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.01rem;
        // text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 110px;
      }

      &__description {
        font-size: 11px;
        color: #9b9b9b;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 185px;
        // letter-spacing: 0.02rem;
        // text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
      }

      &__amount {
        // position: absolute;
        top: 10px;
        right: 15px;
        color: var(--main);
        font-weight: 600;
        font-size: 11px;
      }

      &__icon {
        // width: 50px;
        // height: 50px;
        // border: none;
        // border-radius: 13px;
        // display: flex;
        margin-right: 15px;
        display: flex;
        align-items: center;
        // align-items: center;
        // justify-content: center;
        // background: var(--gray);

        img {
          width: 50px;
        }
      }
    }
  }

  .nextButtons {
    // position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    left: 0;
    margin-top: auto;

    &__active {
      background: var(--main) !important;
    }

    &__next {
      background: #e5e5e5;
      width: 100%;
      height: 55px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      border-radius: 100px;
      margin-bottom: 0;
      text-transform: uppercase;

      &:active {
        opacity: 0.8;
      }
    }

    &__skip {
      background: white;
      width: 100%;
      height: 55px;
      color: var(--main);
      border: 1px solid var(--main);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      border-radius: 100px;
      text-transform: uppercase;

      &:active {
        opacity: 0.8;
      }
    }
  }

  .setupWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>
